import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import styled from 'styled-components';

import { reactiveTemplateConfigSelector } from 'common/selectors/theme';

import loadable from '@loadable/component';
const Container = loadable(() =>
  import('common/components/styleguide/grid/Container')
);
const BannerVariant = loadable(props =>
  import(`common/blocks/banners/${props.blockVariant}`)
);

const makeMapStateToProps = reactiveTemplateBlockPosition => {
  const reactiveTemplateConfig = reactiveTemplateConfigSelector(
    reactiveTemplateBlockPosition,
    false
  );
  return (state, props) => {
    return {
      blockSettings: {
        blockVariant: reactiveTemplateConfig(state, props)
      }
    };
  };
};
const BannerHomepageMainVariant = connect(
  makeMapStateToProps('BannerHomepageMainVariant')
)(({ blockSettings, ...data }) => {
  return (
    <BannerVariant
      blockVariant={'BannerVariant' + blockSettings.blockVariant}
      {...data}
    />
  );
});

const BannerHomepageSecondaryVariant = connect(
  makeMapStateToProps('BannerHomepageSecondaryVariant')
)(({ blockSettings, ...data }) => {
  return (
    <BannerVariant
      blockVariant={'BannerVariant' + blockSettings.blockVariant}
      {...data}
    />
  );
});

const BannerHomepageThirdVariant = connect(
  makeMapStateToProps('BannerHomepageThirdVariant')
)(({ blockSettings, ...data }) => {
  return (
    <BannerVariant
      blockVariant={'BannerVariant' + blockSettings.blockVariant}
      {...data}
    />
  );
});

const BannerCategoryMainVariant = connect(
  makeMapStateToProps('BannerCategoryMainVariant')
)(({ blockSettings, ...data }) => {
  return (
    <BannerVariant
      blockVariant={'BannerVariant' + blockSettings.blockVariant}
      {...data}
    />
  );
});

const BannerProductMainVariant = connect(
  makeMapStateToProps('BannerProductMainVariant')
)(({ blockSettings, ...data }) => {
  return (
    <BannerVariant
      blockVariant={'BannerVariant' + blockSettings.blockVariant}
      {...data}
    />
  );
});

const BannerFooterMainVariant = connect(
  makeMapStateToProps('BannerFooterMainVariant')
)(({ blockSettings, ...data }) => {
  return (
    <BannerVariant
      blockVariant={'BannerVariant' + blockSettings.blockVariant}
      {...data}
    />
  );
});

const BannerArticleMainVariant = connect(
  makeMapStateToProps('BannerArticleMainVariant')
)(({ blockSettings, ...data }) => {
  return (
    <BannerVariant
      blockVariant={'BannerVariant' + blockSettings.blockVariant}
      {...data}
    />
  );
});

const S = {};
S.BannersContainerWrapper = styled.section`
  padding: ${({ theme, position }) => _.get(theme, ['banner', position, 'padding'], _.get(theme, 'banner.homepage.padding'))};
  margin: ${({ theme, position }) => _.get(theme, ['banner', position, 'margin'], _.get(theme, 'banner.homepage.margin'))};
  background: ${({ theme, position }) => _.get(theme, ['banner', position, 'background'], _.get(theme, 'banner.homepage.background'))};
`;

const BannerContainer = ({ position, children }) => {
  return (
    <S.BannersContainerWrapper position={position}>
      <Container>{children}</Container>
    </S.BannersContainerWrapper>
  );
};

const Banners = ({
  t,
  banners = [],
  bannersAdditional1 = null,
  bannersAdditional2 = null,
  position = false,
  useContainer = false
}) => {
  const bannerData = {
    t: t,
    items: banners,
    bannersAdditional1: bannersAdditional1,
    bannersAdditional2: bannersAdditional2
  };

  let resultBanners;
  switch (position) {
    case 'BannerHomepageMainVariant':
      resultBanners = <BannerHomepageMainVariant {...bannerData} />;

    case 'BannerHomepageSecondaryVariant':
      resultBanners = <BannerHomepageSecondaryVariant {...bannerData} />;

    case 'BannerHomepageThirdVariant':
      resultBanners = <BannerHomepageThirdVariant {...bannerData} />;

    case 'BannerCategoryMainVariant':
      resultBanners = <BannerHomepageSecondaryVariant {...bannerData} />;

    case 'BannerProductMainVariant':
      resultBanners = <BannerCategoryMainVariant {...bannerData} />;

    case 'BannerFooterMainVariant':
      resultBanners = <BannerFooterMainVariant {...bannerData} />;

    case 'BannerProductMainVariant':
      resultBanners = <BannerProductMainVariant {...bannerData} />;

    case 'BannerArticleMainVariant':
      resultBanners = <BannerArticleMainVariant {...bannerData} />;

    default:
      resultBanners = <BannerHomepageMainVariant {...bannerData} />;
  }

  return useContainer ? (
    <BannerContainer position={position}>{resultBanners}</BannerContainer>
  ) : (
    resultBanners
  );
};

export default withTranslation()(Banners);
